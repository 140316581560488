/** @format */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { debounce } from "throttle-debounce";

const StyledContainter = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface HintProps {
  children?: any;
  content?: string | ReactNode;
  overlayClassName?: string;
  style?: {};
  size?: any;
}

const getStyle = (element: HTMLElement, property: any) => {
  const css: any = window.getComputedStyle(element, null);
  return property ? css[property] : css;
};

const triggerEllipsis = (target: HTMLElement) => {
  const range = document.createRange();
  range.setStart(target, 0);
  range.setEnd(target, target.childNodes.length);

  const rangeWidth = range.getBoundingClientRect().width;
  const padding =
    (parseInt(getStyle(target, "paddingLeft"), 10) || 0) +
    (parseInt(getStyle(target, "paddingRight"), 10) || 0);

  if (
    rangeWidth + padding > target.offsetWidth + 0 ||
    target.scrollWidth > target.offsetWidth + 0
  ) {
    return true;
  } else {
    return false;
  }
};

const Hint: React.FC<HintProps> = ({
  content,
  style,
  size,
  overlayClassName,
  ...resprops
}) => {
  const ellRef: any = useRef();
  const [isEllipsis, setEllipsis] = useState(false);
  const onReflow = useCallback(() => {
    if (ellRef && ellRef.current) {
      setEllipsis(triggerEllipsis(ellRef.current));
    }
  }, [ellRef]);

  useEffect(() => {
    onReflow();
  }, [onReflow, size]);

  useEffect(() => {
    const debounceFlow = debounce(600, onReflow);
    window.addEventListener("resize", debounceFlow);
    return () => {
      window.removeEventListener("resize", debounceFlow);
    };
  }, [onReflow]);

  const tips = (
    <StyledContainter ref={ellRef} style={style} {...resprops}>
      {content}
    </StyledContainter>
  );

  return (
    <>
      {isEllipsis ? (
        <Tooltip title={content} overlayClassName={overlayClassName}>
          {tips}
        </Tooltip>
      ) : (
        tips
      )}
    </>
  );
};

export default Hint;
