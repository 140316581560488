/** @format */

import React, { lazy, FC } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import * as R from "ramda";
import { Helmet } from "react-helmet";

import { routerName } from "utils/constants";
import { titleName } from "components/shared/common";

const EventsList = lazy(() => import("./Events"));
const Attributes = lazy(() => import("./Attributes"));

interface EventProps {}

const Events: FC<EventProps> = () => {
  const params = useParams();
  const projectId = R.path(["projectId"], params);
  return (
    <>
      <Helmet>
        <title>{`${titleName} Events Management`}</title>
      </Helmet>
      <Routes>
        <Route
          path={`/*`}
          element={
            <Navigate
              replace
              to={`/${routerName}/${projectId}/events/events`}
            />
          }
        />
        <Route path="/events" element={<EventsList />} />
        <Route
          path="/events-attributes"
          element={<Attributes type="EVENT"></Attributes>}
        />
        <Route
          path="/user-attributes"
          element={<Attributes type="USER"></Attributes>}
        />
      </Routes>
    </>
  );
};

export default Events;
