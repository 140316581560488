/** @format */

import React, { FC, useState } from "react";
import styled from "styled-components";
import type { MenuProps } from "antd";
import { Dropdown, Divider } from "antd";
import { path, map } from "ramda";
import { useParams, NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { useSpring, animated } from "react-spring";

import { ProjectNameAvatar } from "components/shared/common";
import arrow from "assets/menu/arrow.svg";
import Hint from "components/shared/elements/Hint";
import ArrowLeft from "assets/menu/ArrowLeft";
import * as api from "api";
import { findProject, selectProjectSpring } from "./SidebarHelper";
import { useTranslation } from "locales";
import { PROJECT_LIST } from "constants/queryKeys";
import { auid } from "utils/stringUtil";
import * as env from "utils/constants";
import { routerName } from "utils/constants";

let eqrcodeVersion = env.eqrcodeVersion === "true";
const Containter = styled(animated.div)`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(247, 248, 250, 0.5);
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  border: 1px solid #e5e6eb;
  border-radius: 2px;
  cursor: pointer;
`;

const StyleProjectInfo = styled.div`
  display: flex;
  align-items: center;
`;

const StyleName = styled(animated.div)`
  margin-left: 12px;
  color: #2e2e2e;
  font-weight: 500;
`;

interface ProjectItemProps {
  isActive?: boolean;
}

const StyleProject = styled.div`
  display: flex;
  position: relative;
  width: 158px;
  height: 40px;
  padding: 0 12px;
  align-items: center;
  color: ${(props: ProjectItemProps) =>
    props.isActive ? "#229BFF" : "#2E2E2E"};
  :before {
    display: ${(props: ProjectItemProps) => !props.isActive && "none"};
    position: absolute;
    left: 0px;
    top: 10px;
    height: 50%;
    content: "";
    border-left: 4px solid #229bff;
  }
`;

const StyledProjectNameAvatar = styled(ProjectNameAvatar)`
  margin-right: 8px;
`;

const StyleBack = styled.div`
  display: flex;
  align-items: center;
  color: #2e2e2e;
  img {
    margin-right: 8px;
  }
`;

interface SelectProjectProps {
  mode?: string;
}

const SelectProject: FC<SelectProjectProps> = ({ mode }) => {
  const params = useParams();
  const { t } = useTranslation("Menu");
  const [open, setOpen] = useState(false);
  const projectId = path(["projectId"], params) || "";
  const { data: projects } = useQuery(PROJECT_LIST, () => api.fetchProjects());

  const handleMenuClick: MenuProps["onClick"] = (e: any) => {
    setOpen(false);
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const { displayName }: any = findProject(
    projectId,
    path(["elements"], projects)
  );

  const containerStyles = useSpring(selectProjectSpring(mode));

  const nameStyles = useSpring({
    to: {
      opacity: mode !== "collapsed" ? 1 : 0,
    },
    config: { duration: 150 },
  });
  if (eqrcodeVersion) {
    return (
      <Containter style={{ ...containerStyles, cursor: "default" }}>
        <StyleProjectInfo>
          <ProjectNameAvatar
            data-auid={auid("insights", "sidebar", `project_img`)}
            data={{ name: displayName }}
            width={28}
            height={28}
            fontSize={13}
          />

          <StyleName
            style={nameStyles}
            data-auid={auid("insights", "sidebar", `project_name`)}
          >
            <Hint content={displayName} style={{ maxWidth: "120px" }} />
          </StyleName>
        </StyleProjectInfo>
      </Containter>
    );
  } else {
    return (
      <Dropdown
        overlayClassName="insights-select-projects-dropdown"
        menu={{
          items: [],
          onClick: handleMenuClick,
        }}
        trigger={["click"]}
        onOpenChange={handleOpenChange}
        open={open}
        dropdownRender={(menus) => {
          return (
            <div className="dropdown-content">
              {menus}
              <div style={{ overflowY: "auto", maxHeight: "350px" }}>
                {map(
                  (i: any) => (
                    <React.Fragment key={i.id}>
                      <NavLink to={`/${routerName}/${i.id}`}>
                        <StyleProject
                          isActive={projectId === i.id}
                          data-auid={auid(
                            "insights",
                            "sidebar",
                            `projects_${i.displayName}`
                          )}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <StyledProjectNameAvatar
                            data={{ name: i.displayName }}
                            width={28}
                            height={28}
                            fontSize={13}
                          />
                          <Hint
                            content={i.displayName}
                            style={{ width: "80%" }}
                          />
                        </StyleProject>
                      </NavLink>
                      <Divider style={{ margin: 0 }} />
                    </React.Fragment>
                  ),
                  path(["elements"], projects) || []
                )}
              </div>

              <div
                style={{
                  height: "38px",
                  lineHeight: "35px",
                  marginLeft: "18px",
                  marginRight: "18px",
                }}
              >
                <NavLink to={`/${routerName}`}>
                  <StyleBack
                    data-auid={auid("insights", "sidebar", `back_to_projects`)}
                  >
                    <ArrowLeft style={{ marginRight: "8px" }} />
                    {t("BackToProjectList")}
                  </StyleBack>
                </NavLink>
              </div>
            </div>
          );
        }}
      >
        <Containter style={{ ...containerStyles }}>
          <StyleProjectInfo>
            <ProjectNameAvatar
              data-auid={auid("insights", "sidebar", `project_img`)}
              data={{ name: displayName }}
              width={28}
              height={28}
              fontSize={13}
            />

            <StyleName
              style={nameStyles}
              data-auid={auid("insights", "sidebar", `project_name`)}
            >
              <Hint content={displayName} style={{ maxWidth: "120px" }} />
            </StyleName>
          </StyleProjectInfo>

          <animated.img src={arrow} alt="arrow" style={nameStyles} />
        </Containter>
      </Dropdown>
    );
  }
};

export default SelectProject;
