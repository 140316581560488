/** @format */
import jwtDecode from "jwt-decode";

export const LOGIN = "auth/LOG_IN";
export const LOGOUT = "auth/LOG_OUT";
export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const namespace = "auth";
export interface authBaseType {
  token: string;
  refreshToken: any;
}

export interface authType extends authBaseType {
  user: string;
}

export type authActions =
  | {
      type: typeof LOGIN;
      payload: authType;
    }
  | {
      type: typeof LOGOUT;
      payload: authType;
    }
  | {
      type: typeof REFRESH_TOKEN;
      payload: authBaseType;
    };

export const initialState = {
  token: "",
  user: "",
  refreshToken: null,
};

export const configs = [
  {
    type: LOGIN,
    state: (state: any, action: any) => ({
      ...state,
      auth: {
        token: action.payload.token,
        user: jwtDecode(action.payload.token),
        refreshToken: action.payload.refreshToken,
      },
    }),
  },
  {
    type: LOGOUT,
    state: (state: any, action: any) => ({
      ...state,
      auth: {
        token: "",
        user: null,
        refreshToken: null,
      },
    }),
  },
  {
    type: REFRESH_TOKEN,
    state: (state: any, action: any) => ({
      ...state,
      auth: {
        ...state.auth,
        user: jwtDecode(action.payload.token),
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      },
    }),
  },
];

const auth = {
  namespace,
  initialState,
  configs,
};

export default auth;
