import React from "react";
import { Navigate, useParams } from "react-router";
import * as R from "ramda";
import * as api from "api";
import { useQuery } from "react-query";
import { responseErrorHandler } from "components/shared/elements/Toast";
import { INVITATION_CONFIRM_KEY } from "constants/queryKeys";
import { routerName } from "utils/constants";

const Invitation = () => {
  const params = useParams();
  const invitationId = R.path(["invitationId"], params);
  const { isLoading: invitationLoading } = useQuery(
    [INVITATION_CONFIRM_KEY, invitationId],
    () => api.confirmInvitation(`${invitationId}`),
    {
      retry: false,
      onError(err: any) {
        responseErrorHandler(err.message);
      },
    }
  );

  return invitationLoading ? (
    <></>
  ) : (
    <Navigate replace to={`/${routerName}/projects`} />
  );
};

export default Invitation;
