/** @format */

import React, { FC } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledNavLink = styled(NavLink)`
  color: #4f4f4f;
  text-decoration: none;
`;

const StyleLabel = styled.div`
  line-height: 20px;
  font-size: 14px;
  color: #4f4f4f;
`;

const StyleItem = styled.div`
  min-width: 150px;
  height: 38px;
  margin-bottom: 0px;
  :hover {
    background: #f7f8fa;
  }
  a.active ${StyleLabel} {
    color: #229bff;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
`;

interface SubMenuItemProps {
  data: {
    key: number;
    link: string;
    name: string;
  };
}

const SubMenuItem: FC<SubMenuItemProps> = ({ data }) => {
  return (
    <StyleItem key={data.key}>
      <StyledNavLink to={data.link}>
        <StyledRow>
          <StyleLabel>{data.name}</StyleLabel>
        </StyledRow>
      </StyledNavLink>
    </StyleItem>
  );
};

export default SubMenuItem;
