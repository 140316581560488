import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    src: url(assets/font/Roboto-Light.ttf);
  }

//   @font-face {
//     font-family: "RobotoRegular";
//     src: url(assets/font/Roboto-Regular.ttf);
//   }

  @font-face {
    src: url(assets/font/Roboto-Medium.ttf);
  }

  body {
    overflow: hidden;
    font-display: optional;
  }


  #nprogress .bar {
   background: #246EFF;  // #5ad3ff;
  }

  .ant-drawer .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .menu-popover .ant-popover-inner{
    padding: 6px !important;
  }

  .li-widgit-delete:hover{
    background: #ffece8 !important;
  }

  .insights-select-projects-dropdown ul.ant-dropdown-menu {
    border-radius: 2px;
  }

  .insights-select-pop {
    border-radius:2px
  }

  .insights-select-projects-dropdown .dropdown-content{
    background: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }
 
  .insights-select-projects-dropdown .dropdown-content .ant-dropdown-menu{
    box-shadow:none;
    overflow-y: auto;
    max-height: 350px;
  }

  .Toastify__toast-container {
    min-width: 340px;
  }

  .Toastify__progress-bar{
    height: 1px;
  }


  .tooltips-widgets-inside {
    max-height: 220px;
    max-width: 400px;
    overflow-y: auto;
  }
  
/////dashboard

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  /* transition: width 200ms ease; 
  transition-property: left, top;*/
  border-radius: 5px;
}

.react-grid-layout .react-draggable{
    cursor: all-scroll;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  z-index: 3;
  /* will-change: transform; */
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #b3e0fa;
  opacity: 0.15;
  /* transition-duration: 100ms; */
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid #65c5fd;
  border-bottom: 2px solid #65c5fd;
  opacity: 0;
}

.react-grid-item:hover .react-resizable-handle::after {
  opacity: 1;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.nonDraggable{
    cursor: default;
}

/**********************************/

#content {
  width: 100%;
}
.react-grid-layout {
  /* background: #fff; */
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #fff;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #fff;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}
.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}
.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

`;
