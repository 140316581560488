/** @format */

import React, { FC } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import * as R from "ramda";
import { Helmet } from "react-helmet";

import { titleName } from "components/shared/common";
import { routerName } from "utils/constants";
import Information from "./Information";

interface ProjectSettingProps {}

const ProjectSetting: FC<ProjectSettingProps> = () => {
  const params = useParams();
  const projectId = R.path(["projectId"], params);
  return (
    <>
      <Helmet>
        <title>{`${titleName} Project Settings`}</title>
      </Helmet>
      <Routes>
        <Route
          path={`/`}
          element={
            <Navigate
              replace
              to={`/${routerName}/${projectId}/project-settings/project-information`}
            />
          }
        />
        <Route path="/project-information/*" element={<Information />} />
      </Routes>
    </>
  );
};

export default ProjectSetting;
