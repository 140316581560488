/** @format */

import React, { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyleSheetManager } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import INSIGHT_SDK from "insights-sdk/dist/es/main";

import GlobalStyle from "./globalStyle";
import ContextProvider from "./ContextProvider";
import { ConfigProvider } from "antd";

interface AppProps {
  apiHelper: any;
}

const App: FC<AppProps> = (props) => {
  //   actions.onGlobalStateChange((state: any, prev: any) => {
  //     console.log(state, prev);
  //   });
  const queryClient = new QueryClient({});

  useEffect(() => {
    INSIGHT_SDK.init({
      client_id: "2edb841d-c1aa-40dd-a859-8ce5366c602d",
      client_secret:
        "bhjPvDugPRu_GB8_wTrUNj5y5Dnds7msy4DvAoLmJy9mhzXn_G3rafsnTuykjVpm",
      report_type: "realTime",
      track_location: true,
    });
  }, []);

  return (
    <ConfigProvider theme={{ token: { fontFamily: "Roboto" } }}>
      <StyleSheetManager disableCSSOMInjection>
        <>
          <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <ContextProvider {...props} />
          </QueryClientProvider>
        </>
      </StyleSheetManager>
    </ConfigProvider>
  );
};

export default App;
