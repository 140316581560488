/** @format */
import React, { FC, ReactNode, forwardRef } from "react";
import { Input, Alert, Modal, Select, Tabs, Spin, Tooltip } from "antd";
import styled from "styled-components";
import { toUpper, path } from "ramda";
import { SearchOutlined } from "@ant-design/icons";

import { stringToHslColor, auid } from "utils/stringUtil";
import { eqrcodeVersion } from "utils/constants";
import Warning from "assets/Warning";
import CssLoading from "components/shared/elements/CssLoading";
import { deleteColor } from "constants/colors";

const isEQRcode =
  typeof eqrcodeVersion === "string"
    ? eqrcodeVersion === "true"
    : eqrcodeVersion;

const { TextArea } = Input;

export const titleName = `${
  isEQRcode ? "Premium Service /" : "Horizon  / Insights /"
}`;

export const StyleInput = styled(Input)`
  border-radius: 2px !important;
`;

export const StyleSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 2px !important;
  }
`;

export const StyleTextArea = styled(TextArea)`
  border-radius: 2px !important;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: #229bff;
  }
  .ant-tabs-nav {
    padding: 0 16px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid #e5e6eb !important;
  }
  .ant-tabs-tab {
    height: 40px !important;
    padding: 1px 8px !important;
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    border-radius: 2px !important;
    color: #4e5969 !important;
    :hover {
      background: #f2f3f5 !important;
      background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ) !important;
    }
    :active {
      background: #f2f3f5 !important;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1)
      ) !important;
    }
    & .ant-tabs-tab-btn:active {
      color: #4e5969 !important;
    }
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #229bff !important;
    font-weight: 400;
  }
`;

export const StyleModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px !important;
  }
  button.ant-modal-close {
    right: 30px;
    top: 12px;
  }
`;

export const PageTitle = styled.div`
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  line-height: 32px;
`;

const StyledProjectNameShort = styled.div`
  background: linear-gradient(
    to top right,
    ${(props: any) => props.backgroundColor || "#435761"},
    ${(props: any) =>
      props.isPlain ? props.backgroundColor : props.hslColor || "#fff"}
  );
  color: #fff;
  width: ${(props: any) => props.width || 76}px;
  height: ${(props: any) => props.height || 76}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: ${(props: any) => props.fontSize || 29}px;
`;

interface ProjectAvatarProps {
  data: {
    name: string;
  };
  children?: any;
  width?: number;
  height?: number;
  fontSize?: number;
  className?: any;
  isPlain?: boolean;
  length?: number;
}

export const ProjectNameAvatar: FC<ProjectAvatarProps> = ({
  data,
  children,
  width,
  height,
  fontSize,
  className,
  length = 1,
  isPlain = false,
  ...resprops
}) => {
  const color: string = stringToHslColor(data.name, 50, 50);
  const lightColor: string = stringToHslColor(data.name, 50, 70);
  const colorProps = {
    hslColor: lightColor,
    backgroundColor: color,
    width: width,
    height: height,
    fontSize: fontSize,
    isPlain: isPlain,
  };

  return (
    <StyledProjectNameShort {...colorProps} className={className} {...resprops}>
      {children || toUpper((path(["name"], data) || "").substring(0, length))}
    </StyledProjectNameShort>
  );
};

export const StyledProjectType = styled.span`
  background: #e6f7ff;
  border-radius: 4px;
  color: #229bff;
  font-size: 14px;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAlert = styled.div`
  .ant-alert-warning {
    background: #fff7e8;
    border: 0;
    border-radius: 2px;
  }
`;

const StyledWarningTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const StyledWarningContent = styled.div`
  font-size: 14px;
`;
interface WarningProps {
  description?: string | ReactNode;
  className?: any;
  style?: any;
}

export const StyledWarning: FC<WarningProps> = ({
  description,
  className,
  style,
}) => {
  return (
    <StyledAlert className={className} style={style}>
      <Alert
        message={<StyledWarningTitle>Warning</StyledWarningTitle>}
        description={<StyledWarningContent>{description}</StyledWarningContent>}
        type="warning"
        icon={<Warning style={{ marginTop: "4px" }} />}
        showIcon
      />
    </StyledAlert>
  );
};

export const StyledSearch = styled(
  ({ placeholder, iconStyle, ...resprops }) => (
    <Input
      suffix={
        <SearchOutlined
          style={{
            color: "#748e99",
            fontSize: "16px",
            marginRight: "2px",
            ...iconStyle,
          }}
        />
      }
      data-auid={auid("common", "dd", "search")}
      {...resprops}
      placeholder={placeholder || "Search name"}
    />
  )
)`
  width: 200px;
  height: 32px;
  border-radius: 2px !important;
`;

export const StyledBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #2e2e2e;
`;

export const StyledActionsButton = styled.span`
  cursor: pointer;
`;

export const StyledDisabledActionsButton = styled.span`
  color: #c9cdd4;
  cursor: not-allowed;
`;

interface ActionsButtonProps {
  className?: any;
  onClick?: () => void;
  disabled?: boolean;
  style?: {};
  children?: any;
  tips?: string | undefined;
}

export const ActionsButton = forwardRef(
  (
    {
      className,
      onClick,
      disabled,
      style,
      tips,
      children,
      ...resprops
    }: ActionsButtonProps,
    ref
  ) => {
    const color = "#949494";
    const disabledButton = (
      <StyledDisabledActionsButton style={style} {...resprops}>
        {children}
      </StyledDisabledActionsButton>
    );
    const disabledButtonWithTips = tips ? (
      <Tooltip title={tips} color={color} key={color}>
        {disabledButton}
      </Tooltip>
    ) : (
      disabledButton
    );
    return disabled ? (
      disabledButtonWithTips
    ) : (
      <StyledActionsButton
        className={className}
        style={style}
        onClick={onClick}
        {...resprops}
      >
        {children}
      </StyledActionsButton>
    );
  }
);

export const ActionsEdit = styled(ActionsButton)`
  color: #229bff;
  cursor: pointer;
  user-select: none;
`;

export const ActionsDelete = styled(ActionsButton)`
  color: ${deleteColor};
`;

interface StyledSpinProps {
  spinning?: boolean;
  children?: any;
  size?: string;
  delay?: number;
  style?: React.CSSProperties;
  wrapperClassName?: string;
  className?: string;
}

export const ResizeCssLoading = ({
  size,
  style = {},
  text,
  afterOpacity,
  ...restProps
}: {
  size: string;
  style?: {};
  text?: string;
  afterOpacity?: number;
}) => {
  const spinSize = {
    small: 0.2,
    middle: 0.4,
    large: 1,
  };

  return (
    <CssLoading
      {...restProps}
      style={{ transform: `scale(${path([size], spinSize)})`, ...style }}
      text={text || (size === "large" && "Loading")}
      afterOpacity={afterOpacity}
    />
  );
};

export const StyledSpin: FC<StyledSpinProps> = ({
  spinning = false,
  size = "large",
  delay = 0,
  children,
  style,
  wrapperClassName,
  className,
}) => {
  const customLoading = (
    <ResizeCssLoading size={size} style={{ height: "100%" }} />
  );

  return (
    <Spin
      style={style}
      wrapperClassName={wrapperClassName}
      className={className}
      indicator={customLoading}
      spinning={spinning}
      delay={delay}
    >
      {children}
    </Spin>
  );
};

export const draggableModelTitleProps = {
  onFocus: () => {},
  onBlur: () => {},
  style: { userSelect: "none", cursor: "all-scroll" },
  className: "handleTitle",
};
