/** @format */

import React, { FC } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { auid } from "utils/stringUtil";

const StyledNavLink = styled(NavLink)`
  color: #4f4f4f;
  text-decoration: none;
`;

const StyleLabel = styled.div`
  line-height: 20px;
  font-size: 14px;
  color: #4f4f4f;
`;

const StyleItem = styled.div`
  width: 200px;
  height: 38px;
  margin-bottom: 5px;
  :hover {
    background: #f7f8fa;
  }
  a.active {
    display: inline-block;
    width: 100%;
    background: linear-gradient(90deg, #f3f9ff 0%, #edf6ff 47.4%, #f3f9ff 100%);
  }
  a.active ${StyleLabel} {
    color: #229bff;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  :hover {
    background: #f7f8fa;
  }
  margin-left: 32px;
`;

interface SubMenuItemProps {
  data: {
    key: number;
    link: string;
    name: string;
  };
}

const SubMenuItem: FC<SubMenuItemProps> = ({ data }) => {
  return (
    <StyleItem key={data.key}>
      <StyledNavLink to={data.link}>
        <StyledRow data-auid={auid("insights", "sidebar", `sub_${data.name}`)}>
          <StyleLabel>{data.name}</StyleLabel>
        </StyledRow>
      </StyledNavLink>
    </StyleItem>
  );
};

export default SubMenuItem;
