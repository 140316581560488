/** @format */

export const apiBase =
  window.REACT_APP_API_BASE || process.env.REACT_APP_API_BASE;
export const apiOauthBase =
  window.REACT_APP_SHELL_API_BASE || process.env.REACT_APP_SHELL_API_BASE;
export const oauthCallback =
  window.REACT_APP_SHELL_OAUTH_CALLBACK ||
  process.env.REACT_APP_SHELL_OAUTH_CALLBACK;
export const clientId =
  window.REACT_APP_SHELL_CLIENT_ID || process.env.REACT_APP_SHELL_CLIENT_ID;
export const eqrcodeVersion =
  window.REACT_APP_EQRCODE_VERSION || process.env.REACT_APP_EQRCODE_VERSION;
export const eqrcodeProjectId =
  window.REACT_APP_EQRCODE_PROJECT_ID ||
  process.env.REACT_APP_EQRCODE_PROJECT_ID;

export const helpEmail =
  window.REACT_APP_SHELL_HELP_EMAIL || process.env.REACT_APP_SHELL_HELP_EMAIL;

export const routerName = eqrcodeVersion === "true" ? "ps" : "insights";

export const userId: string | undefined | null = localStorage.getItem(
  "horizon:last-login-user"
);
