/** @format */

import React from "react";
import styled, { CSSProperties } from "styled-components";
import { map } from "ramda";
import { Breadcrumb } from "antd";
import { NavLink } from "react-router-dom";

import { auid } from "utils/stringUtil";

const StyledContainter = styled.div`
  height: calc(100% - 20px);
  margin-right: 20px;
`;

const StyledHeader = styled.div`
  height: 40px;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-top: 20px !important;
`;

interface ContainerProps {
  children: React.ReactNode;
  rightChildren?: React.ReactNode;
  Navigation: { name: string; link?: string }[];
  style?: {};
  breadcrumbStyle?: {};
}

const Container: React.FC<ContainerProps> = ({
  children,
  Navigation,
  style = {},
  breadcrumbStyle = {},
  rightChildren,
}) => {
  return (
    <StyledContainter style={style}>
      <StyledHeader style={breadcrumbStyle}>
        <div>
          <StyledBreadcrumb>
            {map((i) => {
              let style: CSSProperties = {
                display: "inline",
                fontWeight: "400",
                color: "#949494",
                fontSize: "14px",
              };
              if (
                Navigation.indexOf(i) !== 0 &&
                Navigation.indexOf(i) === Navigation.length - 1
              ) {
                style = {
                  display: "inline",
                  fontWeight: "500",
                  color: "#2E2E2E",
                  fontSize: "14px",
                };
              }
              let viewName: React.ReactNode = <div style={style}>{i.name}</div>;
              if (i.link) {
                style.display = undefined;
                viewName = (
                  <NavLink style={style} to={i.link}>
                    {i.name}
                  </NavLink>
                );
              }
              return (
                <Breadcrumb.Item
                  key={i.name}
                  data-auid={auid("insights", "breadcrumb", `${i.name}`)}
                >
                  {viewName}
                </Breadcrumb.Item>
              );
            }, Navigation)}
          </StyledBreadcrumb>
        </div>
        {rightChildren && <div>{rightChildren}</div>}
      </StyledHeader>

      {children}
    </StyledContainter>
  );
};

export default Container;
